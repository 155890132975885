import {
  Alert,
  Avatar,
  Checkbox,
  IconButton,
  MenuItem,
  Popover,
  Snackbar,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Label from '../components/label';
import Iconify from '../components/iconify';

import ViewModal from './ViewModal/ViewModal';
import { NFT_CONTRACT, IPFS_URL, EXPLORER_URL, API_URL } from '../config';
import NFT_CONTRACT_ABI from '../config/NFT_CONTRACT_ABI.json';
import CommissionModal from './CommissionModal';

const AffiliatedTableListRow = ({
  row,
  selected,
  // handleOpenMenu,
  handleClick,
  // handleCloseMenu, 
  // open,
  setShowSnackbar,
  setShowSnackbarRej,
  getAffiliateData
}) => {
  const { id, firstName,
    lastName, email,approved,requestForApproval , wallet, avatarUrl, ipfs,status,username } = row;
  const selectedUser = selected.indexOf(firstName) !== -1;

  const [view, setView] = useState(false);
  const [createCollection, setCreateCollection] = useState(false);
  const [commissionModal, setCommissionModal] = useState(false);
  const handleClickOpen = (type) => {
    setView(true);
    setCreateCollection(type)
  };
  const handleClickClose = () => {
    setView(false);
  };
  const [open, setOpen] = useState(null);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const [girlData, setGirlData] = useState([]);
  const [photographerData, setPhotographerData] = useState([]);
  const getGirlData = async () => {
    await axios({
      method: 'GET',
      url: `${API_URL}/api/users?filters[user_type][$eq]=Girl${`&filters[referredBy][$eq]=${username}`}&populate=*`,
      headers: {
        'Accept-Encoding': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,

        // 'Origin' : process.env.ORIGIN
      },
    })
      .then((_data) => {
        // console.log(_data);
        setGirlData(_data.data);
      })
      .catch((err) => {
        //  throw err
        console.log(err);
      });
  };
  const getPhotographerData = async () => {
    await axios({
      method: 'GET',
      url: `${API_URL}/api/users?filters[user_type][$eq]=Photographer${`&filters[referredBy][$eq]=${username}`}&populate=*`,
      headers: {
        'Accept-Encoding': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,

        // 'Origin' : process.env.ORIGIN
      },
    })
      .then((_data) => {
        // console.log(_data);
        setPhotographerData(_data.data);
      })
      .catch((err) => {
        //  throw err
        console.log(err);
      });
  };
  useEffect(()=>{
    getGirlData()
    getPhotographerData()
  },[username])
  const profileApproved = async () => {
    const config = {
      headers: {
        // authorization: localStorage.getItem(JWT),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    try {
      const data = await axios.post(
        `${API_URL}/api/users/approve-user`,
        { id },
        config
      );
      console.log(data);
      if (data.data.status === 'Approved') {
        setShowSnackbar(true);
        handleCloseMenu();
        getAffiliateData();
        setTimeout(() => {
          setShowSnackbar(false);
        }, 2000);
        // window.location.reload();
      }
      // console.log(data);
      // setEarningData(data.data);
    } catch (error) {
      console.log('catch', error);
    }
  };
  const profileRejected = async () => {
    const config = {
      headers: {
        // authorization: localStorage.getItem(JWT),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    try {
      const data = await axios.post(
        `${API_URL}/api/users/reject-user`,
        { id },
        config
      );
      console.log(data);
      if (data.data.status === 'Rejected') {
        setShowSnackbar(true);
        getAffiliateData();
        handleCloseMenu();
        setTimeout(() => {
          setShowSnackbar(false);
        }, 2000);
      }
      // console.log(data);
      // setEarningData(data.data);
    } catch (error) {
      console.log('catch', error);
    }
  };

  return (
    <>
      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
        <TableCell align="left">{id}</TableCell>

        <TableCell component="th" scope="row" padding="none">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar alt={firstName} src={avatarUrl} />
            <Typography variant="subtitle2" noWrap>
            {firstName || "NA"} {lastName} 
            </Typography>
          </Stack>
        </TableCell>

        <TableCell align="left">{email}</TableCell>

        <TableCell align="left">
           {girlData?.length>0?<Link to={`/dashboard/girls?referredBy=${username}`}>
             View ({girlData?.length})
            </Link>:"0"}
            </TableCell>
        <TableCell align="left">
           {photographerData?.length>0?<Link to={`/dashboard/photographers?referredBy=${username}`}>
             View ({photographerData?.length})
            </Link>:"0"}
            </TableCell>
  <TableCell align="left">
        
  <Label color={status === 'Approved' ? 'success' : 'error'}>
            {status === 'Approved'
              ? 'Approved'
              : status === 'Pending'
              ? 'Approval Requested'
              : status === 'Rejected'
              ? 'Rejected'
              : 'Profile Incomplete'}
          </Label>
         
        </TableCell>
        <TableCell align="right">
          <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
            <Iconify icon={'eva:more-vertical-fill'} />
          </IconButton>
        </TableCell>
      </TableRow>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 200,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {/* {isConnected ? (
          <>
           {!approved  && requestForApproval && ( */}
            {/* <> */}
            {status !== 'Approved' && status === 'Pending' && (
            <MenuItem onClick={profileApproved}>
          <Iconify icon={'ant-design:check-circle-outlined'} sx={{ mr: 2 }} />
          Approve
        </MenuItem>
        )}
          {status !== 'Approved' && status === 'Pending' && (

        <MenuItem sx={{ color: 'error.main' }} onClick={profileRejected}>
          <Iconify icon={'ant-design:close-circle-outlined'} sx={{ mr: 2 }} />
          Reject  
        </MenuItem>
        )}

        {/* </> */}
           {/* )} */}
            <MenuItem onClick={()=>handleClickOpen(0)}>
              <Iconify icon={'ant-design:check-circle-outlined'} sx={{ mr: 2 }} />
              View
            </MenuItem>
            <MenuItem onClick={()=>setCommissionModal(true)}>
              <Iconify icon={'ant-design:check-circle-outlined'} sx={{ mr: 2 }} />
              Update Commission
            </MenuItem>
         
          {/* </>
        ) : (
          <ConnectWalletBtn />
        )} */}
        <ViewModal open={view} handleClose={handleClickClose} viewData={row} createCollection={createCollection}/>
      <CommissionModal open={commissionModal} handleClose={()=>setCommissionModal(false)} viewData={row} getGirlData={getAffiliateData}/>

      </Popover>
    </>
  );
};

export default AffiliatedTableListRow;
