import { Avatar, IconButton, MenuItem, Popover, Stack, TableCell, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAccount } from 'wagmi';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Label from '../components/label';
import Iconify from '../components/iconify';
import ViewModal from './ViewModal/ViewModal';
import { IPFS_URL, EXPLORER_URL, API_URL } from '../config';
import CommissionModal from './CommissionModal';
import AddGirlModal from './AddGirlModal';

const PhotographerTableRow = ({
  row,
  selected,
  // handleOpenMenu,
  // handleCloseMenu,
  // open,
  setShowSnackbar,
  setShowSnackbarRej,
  getPhotographerData,
}) => {
  const { id, firstName, lastName, email, avatarUrl, ipfs, status, username } = row;

  const selectedUser = selected.indexOf(firstName) !== -1;
  const [createCollection, setCreateCollection] = useState(false);
  const [commissionModal, setCommissionModal] = useState(false);
  const [addGirlOpen, setAddGirlOpen] = useState(false);
  const { address, isConnected } = useAccount();

  const [view, setView] = useState(false);
  const handleClickOpen = (type) => {
    setView(true);
    setCreateCollection(type);
  };
  const handleClickClose = () => {
    setView(false);
  };
  const [open, setOpen] = useState(null);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };
  const [girlData, setGirlData] = useState([]);
  const getGirlData = async () => {
    await axios({
      method: 'GET',
      url: `${API_URL}/api/users?filters[user_type][$eq]=Girl${`&filters[referredBy][$eq]=${username}`}&populate=*`,
      headers: {
        'Accept-Encoding': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,

        // 'Origin' : process.env.ORIGIN
      },
    })
      .then((_data) => {
        // console.log(_data);
        setGirlData(_data.data);
      })
      .catch((err) => {
        //  throw err 
        console.log(err);
      });
  };
  useEffect(() => {
    getGirlData();
  }, [username]);

  const profileApproved = async () => {
    const config = {
      headers: {
        // authorization: localStorage.getItem(JWT),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    try {
      const data = await axios.post(`${API_URL}/api/users/approve-user`, { id }, config);
      // console.log(data);
      if (data.data.status === 'Approved') {
        setShowSnackbar(true);
        handleCloseMenu();
        getPhotographerData();
        setTimeout(() => {
          setShowSnackbar(false);
        }, 2000);
        // window.location.reload();
      }
      // console.log(data);
      // setEarningData(data.data);
    } catch (error) {
      console.log('catch', error);
    }
  };
  const profileRejected = async () => {
    const config = {
      headers: {
        // authorization: localStorage.getItem(JWT),
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    try {
      const data = await axios.post(`${API_URL}/api/users/reject-user`, { id }, config);
      console.log(data);
      if (data.data.status === 'Rejected') {
        setShowSnackbar(true);
        getPhotographerData();
        handleCloseMenu();
        setTimeout(() => {
          setShowSnackbar(false);
        }, 2000);
      }
      // console.log(data);
      // setEarningData(data.data);
    } catch (error) {
      console.log('catch', error);
    }
  };
  const url = `${IPFS_URL}/${ipfs}`;
  // console.log(url);
  // const { config: registerPhotographerConfig } = usePrepareContractWrite({
  //   address: NFT_CONTRACT,
  //   abi: NFT_CONTRACT_ABI,
  //   functionName: "registerPhotographer",
  //   args: [url, wallet, 1],
  // });
  // const {
  //   data: registerPhotographerData,
  //   isLoading: registerPhotographerLoading,
  //   isSuccess: registerPhotographerSuccess,
  //   isError: registerPhotographerError,
  //   write: registerPhotographerWrite,
  // } = useContractWrite(registerPhotographerConfig);
  // const { isSuccess: waitTxnSuccess, isError:waitTxnError,} =
  //   useWaitForTransaction({
  //     hash: registerPhotographerData?.hash,
  //   });
  // // console.log(waitTxnSuccess);
  // const registerPhotographerBtn = async () => {
  //   await registerPhotographerWrite?.()
  // };
  return (
    <>
      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
        <TableCell align="left">{id}</TableCell>

        <TableCell component="th" scope="row" padding="none">
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar alt={firstName} src={avatarUrl} />
            <Typography variant="subtitle2" noWrap>
              {firstName || 'NA'} {lastName}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell align="left">{email}</TableCell>

        <TableCell align="left">
          {girlData?.length > 0 ? (
            <Link to={`/dashboard/girls?referredBy=${username}`}>View ({girlData?.length})</Link>
          ) : (
            '0'
          )}
        </TableCell>

        <TableCell align="left">
          <Label color={status === 'Approved' ? 'success' : 'error'}>
            {status === 'Approved'
              ? 'Approved'
              : status === 'Pending'
              ? 'Approval Requested'
              : status === 'Rejected'
              ? 'Rejected'
              : 'Profile Incomplete'}
          </Label>
        </TableCell>
        <TableCell align="right">
          <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
            <Iconify icon={'eva:more-vertical-fill'} />
          </IconButton>
        </TableCell>
      </TableRow>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 200,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        {/* {isConnected ? (
          <>
            
            {!approved  && requestForApproval && ( */}
        <>
          {status !== 'Approved' && status === 'Pending' && (
            <MenuItem onClick={profileApproved}>
              <Iconify icon={'ant-design:check-circle-outlined'} sx={{ mr: 2 }} />
              Approve
            </MenuItem>
          )}
          {status !== 'Approved' && status === 'Pending' && (
            <MenuItem sx={{ color: 'error.main' }} onClick={profileRejected}>
              <Iconify icon={'ant-design:close-circle-outlined'} sx={{ mr: 2 }} />
              Reject
            </MenuItem>
          )}
        </>
        {/* )} */}
        <MenuItem onClick={() => handleClickOpen(0)}>
          <Iconify icon={'ant-design:check-circle-outlined'} sx={{ mr: 2 }} />
          View
        </MenuItem>
        <MenuItem onClick={() => setCommissionModal(true)}>
          <Iconify icon={'ant-design:check-circle-outlined'} sx={{ mr: 2 }} />
          Update Commission
        </MenuItem>
        <MenuItem onClick={() => setAddGirlOpen(true)}>
          <Iconify icon={'ant-design:check-circle-outlined'} sx={{ mr: 2 }} />
          Add girl
        </MenuItem>
        {/* </>
        ) : (
          <ConnectWalletBtn />
        )} */}
        <ViewModal open={view} handleClose={handleClickClose} viewData={row} createCollection={createCollection} />
        <CommissionModal
          open={commissionModal}
          handleClose={() => setCommissionModal(false)}
          viewData={row}
          getGirlData={getPhotographerData}
        />
        <AddGirlModal
          open={addGirlOpen}
          handleClose={() => setAddGirlOpen(false)}
          viewData={row}
          getGirlData={getGirlData}
        />
      </Popover>
    </>
  );
};

export default PhotographerTableRow;
